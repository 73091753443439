import { MutableRefObject, useEffect, useLayoutEffect } from "react";
import { scrollIntoView } from "../utils";
import useGetter from "./useGetter";

type PhraseHotkeyProps = {
  phraseTestRef: MutableRefObject<HTMLDivElement | null>;
  revealed: boolean | undefined;
  onReveal(): void;
  onRate: (ratingLabel: "hard" | "easy") => void;
  focusContainer?: boolean;
};

const rateKeys: { [key: string]: "easy" | "hard" } = {
  1: "easy",
  2: "hard",
};

export default function usePhraseHotkeys(props: PhraseHotkeyProps): void {
  const getProps = useGetter(props);

  useLayoutEffect(() => {
    const currentPhraseTest = getProps().phraseTestRef.current;
    if (currentPhraseTest) {
      // Fixes a scrolling issue
      setTimeout(() => {
        currentPhraseTest.focus();
        const { top } = currentPhraseTest.getBoundingClientRect();
        if (top < 60) {
          scrollIntoView(currentPhraseTest, { instant: true });
        }
      }, 1);
    }
  }, [getProps]);

  useEffect(() => {
    const keyPressHandler = (e: KeyboardEvent) => {
      const currentProps = getProps();

      const rateKey = rateKeys[e.key];
      if (currentProps.revealed && rateKey) {
        e.preventDefault();
        currentProps.onRate(rateKey);
        return;
      }

      if (!currentProps.revealed && e.key === "Enter") {
        // Pressing enter on a focused button shouldn't attempt to reveal
        // The user likely intends to play or record the phrase
        if (e.target instanceof HTMLButtonElement) {
          return;
        }

        // Reveal the phrase
        e.preventDefault();
        currentProps.onReveal();
        return;
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [getProps]);
}
