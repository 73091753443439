import type { RateableTestEntity } from "@rocketlanguages/types";
import { useRateableTestRating } from "../../../hooks/useLessonRateableTests";
import { RateableTestMetadata } from "../../../utils/constants-web";
import { useSharedSelector } from "../../../store";
import { twMerge } from "tailwind-merge";

function useIsTestInProgress(rateableTest: RateableTestEntity) {
  const store = useSharedSelector((store) => {
    return {
      testRatings: store.lesson.entities.user_rateable_test_ratings[rateableTest.id],
      testProgress: store.lesson.entities.user_rateable_test_progress[rateableTest.id],
    };
  });

  if ((store.testRatings?.value || 0) > 0 || store.testRatings?.marked_complete) {
    return false;
  }

  return (store.testProgress?.num_components_rated || 0) > 0;
}

export function Icon({
  rateableTest,
  className,
  iconClassName,
  strokeWidth,
}: {
  rateableTest: RateableTestEntity;
  className?: string;
  iconClassName?: string;
  strokeWidth?: number;
}) {
  const rating = useRateableTestRating(rateableTest.id);
  const isInProgress = useIsTestInProgress(rateableTest);

  const Icon = RateableTestMetadata[rateableTest.rateable_test_type_id]?.icon;
  if (!Icon) {
    console.warn(`No icon found for rateable test type ${rateableTest.rateable_test_type_id}`);
    return null;
  }

  const rated = rating !== 0;

  return (
    <div
      aria-hidden
      className={twMerge(
        "relative flex h-10 w-10 items-center justify-center rounded-full border",
        isInProgress && "bg-missilegreen/20 border-missilegreen",
        !isInProgress && !rated && "border-neutral-500/10 bg-missilestroke dark:bg-missilesurfacedark",
        rated && "border-green-600 bg-missilegreen",
        className,
      )}
    >
      <Icon
        className={twMerge(
          "h-5 w-5",
          isInProgress && "text-missilegreen",
          !isInProgress && !rated && "text-missilegray2 dark:text-white",
          rated && "text-white",
          iconClassName,
        )}
        strokeWidth={strokeWidth}
      />
      {rating > 0 && rating < 100 ? (
        <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-orange-600" />
      ) : null}
    </div>
  );
}
