import { memo } from "react";
import type { Phrase } from "@rocketlanguages/types";
import { clsx } from "clsx";
import type useMedia from "../../../../hooks/useMedia";

type TranscriptLineProps = {
  index: number;
  activeIndex: number;
  subtitle: TextTrackCue & { text?: string };
  phrase?: Phrase;
  player?: ReturnType<typeof useMedia>;
  onClick?: () => void;
};

function TranscriptLine(props: TranscriptLineProps) {
  const { index, activeIndex, subtitle, player } = props;

  const isActive = activeIndex === index;

  const handleLineClick = () => {
    if (player?.isPlaying) {
      player?.seekAndPlay(subtitle.startTime + 0.1);
    } else {
      player?.seek(subtitle.startTime + 0.1);
    }
  };

  return (
    <div data-index={index}>
      <div
        className={clsx(
          "cursor-pointer py-1 text-slate-400 hover:text-text1",
          isActive && "font-bold text-text1 dark:text-text2",
        )}
        onClick={props.onClick || handleLineClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleLineClick();
          }
        }}
      >
        <div>{"text" in subtitle ? subtitle.text : ""}</div>
      </div>
    </div>
  );
}

const MemoizedTranscriptLine = memo(TranscriptLine);

export default MemoizedTranscriptLine;
