import { RoundedButton } from "@rocketlanguages/ui";
import CongratulationsMessage from "./includes/CongratulationsMessage";
import { Link } from "react-router-dom";
import ResetTestButton from "./buttons/ResetTestButton";
import { useContext, useState } from "react";
import { clsx } from "clsx";
import RateableTestContext from "../includes/context";
import { LessonPhrasebox } from "../../PhraseboxFacelift/PhraseboxFacelift";
import memoize from "nano-memoize";
import type { Phrase, VideoComponent } from "@rocketlanguages/types";

export default function Complete(props: {
  correctCount: number;
  incorrectCount: number;
  completedWithinSession: boolean;
  nextActivityTitle?: string;
  nextActivityHref?: string;
  testName: string;
  onConfirmReset: () => void;
  onReviewHardPhrases: () => void;
}) {
  const [mode, setMode] = useState<"complete" | "review">("complete");

  if (mode === "review") {
    return (
      <div className="space-y-8">
        <ReviewPhrasesScreen onRedo={props.onReviewHardPhrases} />
      </div>
    );
  }

  const shouldRetry = (props.correctCount * 100) / (props.correctCount + props.incorrectCount) < 80;

  return (
    <div className="mx-auto flex w-96 max-w-96 flex-1 flex-col justify-evenly gap-y-8">
      <div className="relative h-20">
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <TrophySvg />
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <TrophyConfettiSvg />
        </div>
      </div>
      {props.completedWithinSession ? <CongratulationsMessage /> : null}
      <div className="rounded-2xl border border-missilestroke p-3">
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold leading-5">Easy</div>
          <div className="h-5 w-9 rounded-lg bg-missilegreen px-2 py-1">
            <div className="text-center text-xs font-semibold leading-3 text-white">{props.correctCount}</div>
          </div>
        </div>
        <div className="my-4 border border-dashed border-missilestroke" />
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold leading-5">Hard</div>
          <div className="h-5 w-9 rounded-lg bg-missileaccent px-2 py-1">
            <div className="text-center text-xs font-semibold leading-3 text-white">{props.incorrectCount}</div>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {props.nextActivityHref && props.nextActivityTitle ? (
          <Link to={props.nextActivityHref}>
            <RoundedButton
              className={clsx(
                "w-full font-semibold",
                shouldRetry
                  ? "border border-missilebrand text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white"
                  : "hover:bg-missileaccent/90 bg-missileaccent text-white",
              )}
            >
              {props.nextActivityTitle}
            </RoundedButton>
          </Link>
        ) : null}
        {props.incorrectCount > 0 && (
          <RoundedButton
            color="primary"
            className={clsx(
              "w-full font-semibold",
              shouldRetry
                ? "hover:bg-missileaccent/90 bg-missileaccent text-white"
                : "border border-missilebrand text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white",
            )}
            onClick={() => setMode("review")}
          >
            Review {props.incorrectCount} Hard Phrase{props.incorrectCount > 1 ? "s" : ""}
          </RoundedButton>
        )}
        <ResetTestButton className="w-full" testName={props.testName} onConfirmReset={props.onConfirmReset} />
      </div>
    </div>
  );
}

export function TrophyConfettiSvg() {
  return (
    <svg width="317" height="76" viewBox="0 0 317 76" fill="none">
      <title>Trophy Confetti</title>
      <circle cx="21" cy="68" r="3" fill="#819EE8" />
      <circle cx="86" cy="45" r="3" fill="#E081E8" />
      <circle cx="233" cy="32" r="3" fill="#FFD88D" />
      <circle cx="314" cy="71" r="3" fill="#81E8D5" />
      <circle cx="286" cy="3" r="3" fill="#FF7474" />
      <circle cx="3" cy="9" r="3" fill="#AEE881" />
    </svg>
  );
}

export function TrophySvg() {
  return (
    <svg width="317" height="76" viewBox="0 0 317 76" fill="none">
      <title>Trophy</title>
      <g clipPath="url(#clip0_557_5463)">
        <path
          d="M143.062 38.7143C131.419 37.6209 122.5 27.9658 122.5 16.2539V12.1523C122.5 8.7598 125.26 6 128.652 6C132.045 6 134.805 8.7598 134.805 12.1523C134.805 13.2838 135.724 14.2031 136.855 14.2031H141.094C142.227 14.2031 143.145 15.1204 143.145 16.2539C143.145 17.3874 142.227 18.3047 141.094 18.3047H136.855C133.463 18.3047 130.703 15.5449 130.703 12.1523C130.703 11.0209 129.784 10.1016 128.652 10.1016C127.521 10.1016 126.602 11.0209 126.602 12.1523V16.2539C126.602 25.8369 133.922 33.7356 143.447 34.6288C144.574 34.7349 145.402 35.7363 145.297 36.8638C145.191 37.9878 144.196 38.8174 143.062 38.7143Z"
          fill="#FFBE40"
        />
        <path
          d="M169.839 36.8638C169.735 35.7363 170.562 34.735 171.689 34.6288C181.214 33.7356 188.398 25.8369 188.398 16.2539V12.1523C188.398 11.0209 187.479 10.1016 186.347 10.1016C185.216 10.1016 184.297 11.0209 184.297 12.1523C184.297 15.5449 181.537 18.3047 178.144 18.3047H174.043C172.909 18.3047 171.992 17.3874 171.992 16.2539C171.992 15.1204 172.909 14.2031 174.043 14.2031H178.144C179.276 14.2031 180.195 13.2838 180.195 12.1523C180.195 8.75981 182.955 6 186.347 6C189.74 6 192.5 8.75981 192.5 12.1523V16.2539C192.5 27.9658 183.718 37.6209 172.074 38.7143C170.94 38.8174 169.945 37.988 169.839 36.8638Z"
          fill="#FF9F40"
        />
        <path
          d="M174.043 6H157.5H141.094C139.96 6 139.043 6.91725 139.043 8.05078V28.5586C139.043 36.3993 144.028 43.3166 151.348 45.9001V61.6445H157.5H163.652V45.9001C170.972 43.3167 176.094 36.3993 176.094 28.5586V8.05078C176.094 6.91725 175.177 6 174.043 6Z"
          fill="#FFDF40"
        />
        <path
          d="M163.652 45.9001C170.972 43.3167 176.094 36.3993 176.094 28.5586V8.05078C176.094 6.91725 175.177 6 174.043 6H157.5V61.6445H163.652V45.9001Z"
          fill="#FFBE40"
        />
        <path
          d="M167.974 21.8136C167.732 21.0705 167.091 20.5298 166.318 20.4177L161.495 19.7167L159.338 15.3468C158.994 14.6458 158.247 14.2954 157.5 14.2954C156.753 14.2954 156.006 14.6458 155.661 15.3468L153.505 19.7167L148.682 20.4177C147.909 20.5298 147.268 21.0705 147.026 21.8136C146.785 22.5546 146.986 23.3697 147.545 23.9144L151.035 27.3171L150.212 32.1196C150.08 32.8886 150.396 33.6657 151.027 34.1243C151.658 34.5849 152.503 34.645 153.186 34.2805L157.5 32.0154L161.814 34.2804C162.494 34.6413 163.337 34.5886 163.973 34.1241C164.603 33.6656 164.92 32.8885 164.788 32.1194L163.964 27.3169L167.455 23.9143C168.014 23.3697 168.214 22.5546 167.974 21.8136Z"
          fill="#FFBE40"
        />
        <path
          d="M163.973 34.1243C164.603 33.6657 164.92 32.8886 164.788 32.1196L163.965 27.3171L167.455 23.9144C168.014 23.3697 168.214 22.5546 167.974 21.8136C167.732 21.0705 167.091 20.5298 166.318 20.4177L161.495 19.7167L159.338 15.3468C158.994 14.6458 158.247 14.2954 157.5 14.2954V32.0154L161.814 34.2804C162.494 34.6415 163.337 34.5887 163.973 34.1243Z"
          fill="#FF9F40"
        />
        <path
          d="M173.906 67.7969H171.855V61.6445C171.855 60.511 170.938 59.5938 169.805 59.5938H157.5H145.195C144.062 59.5938 143.145 60.511 143.145 61.6445V67.7969H141.094C139.96 67.7969 139.043 68.7141 139.043 69.8477V73.9492C139.043 75.0828 139.96 76 141.094 76H157.5H173.906C175.04 76 175.957 75.0828 175.957 73.9492V69.8477C175.957 68.7141 175.04 67.7969 173.906 67.7969Z"
          fill="#146987"
        />
        <path
          d="M175.957 73.9492V69.8477C175.957 68.7141 175.04 67.7969 173.906 67.7969H171.855V61.6445C171.855 60.511 170.938 59.5938 169.805 59.5938H157.5V76H173.906C175.04 76 175.957 75.0828 175.957 73.9492Z"
          fill="#0B5772"
        />
        <path
          d="M161.602 65.7461H157.5H153.398C152.265 65.7461 151.348 66.6633 151.348 67.7969C151.348 68.9304 152.265 69.8477 153.398 69.8477H157.5H161.602C162.735 69.8477 163.652 68.9304 163.652 67.7969C163.652 66.6633 162.735 65.7461 161.602 65.7461Z"
          fill="#08506A"
        />
        <path
          d="M163.652 67.7969C163.652 66.6633 162.735 65.7461 161.602 65.7461H157.5V69.8477H161.602C162.735 69.8477 163.652 68.9304 163.652 67.7969Z"
          fill="#023B4F"
        />
      </g>
      <defs>
        <clipPath id="clip0_557_5463">
          <rect width="70" height="70" fill="white" transform="translate(122.5 6)" />
        </clipPath>
      </defs>
    </svg>
  );
}

function ReviewPhrasesScreen({ onRedo }: { onRedo: () => void }) {
  const context = useContext(RateableTestContext);
  const phraseTest = context?.phraseTest;

  // @ts-expect-error Flashcards come from a legacy custom flashcard test
  const { phrases, videoComponents, flashcards } = phraseTest.components;
  const { ratings } = phraseTest.computed;

  const phrasesArray = typeof flashcards !== "undefined" ? (flashcards as Phrase[]) : phrases;

  return (
    <div className="space-y-8">
      <RoundedButton
        className="hover:bg-missileaccent/90 mx-auto w-96 bg-missileaccent font-semibold text-white"
        onClick={onRedo}
      >
        Redo {ratings.hard.size + ratings.good.size} hard phrases
      </RoundedButton>
      {videoComponents?.length > 0 ? (
        <ReviewTranslateItPhrases
          videoComponents={videoComponents}
          hardRatedPhrasIds={[...ratings.hard, ...ratings.good]}
        />
      ) : (
        <ReviewNormalPhrases hardRatedPhrasIds={[...ratings.hard, ...ratings.good]} phrases={phrasesArray} />
      )}
    </div>
  );
}

function ReviewTranslateItPhrases(props: { videoComponents: VideoComponent[]; hardRatedPhrasIds: number[] }) {
  return (
    <>
      {props.hardRatedPhrasIds.map((phraseId) => {
        const videoComponent = props.videoComponents.find((v) => v.id === phraseId);
        if (!videoComponent) {
          return null;
        }
        return (
          <div key={`p.${videoComponent.id}`}>
            <div
              dangerouslySetInnerHTML={{
                // Note: the caption contains html entities
                // This ideally needs to be converted to ascii/utf8
                __html: videoComponent.caption,
              }}
            />
          </div>
        );
      })}
    </>
  );
}

function ReviewNormalPhrases(props: { hardRatedPhrasIds: number[]; phrases: Phrase[] }) {
  return (
    <>
      {props.hardRatedPhrasIds.map((phraseId) => {
        const phrase = findPhraseById(props.phrases, phraseId);
        if (!phrase) {
          return null;
        }
        return <LessonPhrasebox RatingButtonElement={null} phrase={phrase} key={phraseId} />;
      })}
    </>
  );
}

const findPhraseById = memoize((phrases: Phrase[], phraseId: number) => {
  return phrases.find((p: Phrase) => p.id === phraseId);
});
