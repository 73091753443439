import { type HTMLAttributes, useContext } from "react";
import { ModalFacelift, SimpleTooltip } from "@rocketlanguages/ui";
import type { IconType } from "react-icons";
import { RateableTestMetadata } from "../../../utils/constants-web";
import { RateableTestUIContext } from "./includes/context";
import useActiveProduct from "../../../hooks/useActiveProduct";
import useCanMarkAsComplete from "../../../hooks/useCanMarkAsComplete";
import { useSharedDispatch } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";
import { InfoCircle as InfoCircleIcon, Settings as SettingsIcon } from "iconoir-react";
import { clsx } from "clsx";
import { useNumTestComponents } from "../../../hooks/useRateableTest/useRateableTest";
import LessonContext from "../../../context/LessonContext";

type HeaderProps = HTMLAttributes<HTMLDivElement> & {
  rateableTestTypeId?: number;
  testName: string;
  testSubheading?: string;
  ratingLevel?: number;
  icon?: IconType;
  rateableTestId?: number;
  position?: number;
  total?: number;
  hideProgress?: boolean;
};

export default function Header(props: HeaderProps) {
  const context = useContext(RateableTestUIContext);
  return (
    <>
      <div className="flex w-full flex-wrap items-center justify-between gap-2">
        <div className="flex items-center gap-5">
          {!!props.rateableTestId && !!props.rateableTestTypeId ? (
            <RateableTestIconWithTestData
              rateableTestTypeId={props.rateableTestTypeId}
              rateableTestId={props.rateableTestId}
              icon={props.icon}
              rating={props.ratingLevel || 0}
            />
          ) : (
            <RateableTestIconWithoutTestData rating={props.ratingLevel || 0} icon={props.icon} />
          )}
          <div>
            <h3 className="text-xl font-bold">{props.testName}</h3>
            {props.testSubheading && (
              <h4 className="font-normal leading-5 text-missilegray dark:text-missiledark">{props.testSubheading}</h4>
            )}
          </div>
        </div>
        {props.hideProgress ? (
          <div className="flex gap-2">
            {!!context?.instructions && (
              <SimpleTooltip
                content="Open Instructions"
                aria-label="Open Instructions"
                onClick={context.toggleInstructions}
                className="rounded-xl border border-missilestroke bg-missilesurfacelight p-2 hover:bg-missilesurfacedark"
              >
                <InfoCircleIcon />
              </SimpleTooltip>
            )}
            {!!context?.settings && (
              <SimpleTooltip
                content="Open Settings"
                aria-label="Open Settings"
                onClick={context.toggleSettings}
                className="rounded-xl border border-missilestroke bg-missilesurfacelight p-2 hover:bg-missilesurfacedark"
              >
                <SettingsIcon />
              </SimpleTooltip>
            )}
          </div>
        ) : null}
      </div>

      <div className="py-4">
        <div className="w-full border-t border-missilestroke dark:border-gray-600" />
      </div>

      {!props.hideProgress ? (
        <div className="flex h-12 items-center justify-between px-2 pb-4 print:hidden">
          <div className="text-lg font-semibold">
            {props.position !== undefined ? props.position : "..."}/{props.position !== undefined ? props.total : "..."}
          </div>
          <div className="space-x-2">
            {!!context?.instructions && (
              <SimpleTooltip
                content="Open Instructions"
                aria-label="Open Instructions"
                onClick={context.toggleInstructions}
                className="rounded-xl border border-missilestroke bg-missilesurfacelight p-2 hover:bg-missilesurfacedark"
              >
                <InfoCircleIcon />
              </SimpleTooltip>
            )}
            {!!context?.settings && (
              <SimpleTooltip
                content="Open Settings"
                aria-label="Open Settings"
                onClick={context.toggleSettings}
                className="rounded-xl border border-missilestroke bg-missilesurfacelight p-2 hover:bg-missilesurfacedark"
              >
                <SettingsIcon />
              </SimpleTooltip>
            )}
          </div>
        </div>
      ) : null}
      {!!context?.instructions && (
        <ModalFacelift
          isOpen={context.instructionsVisible}
          onEscapeKeyDown={context.toggleInstructions}
          onPointerDownOutside={context.toggleInstructions}
          onClose={context.toggleInstructions}
        >
          {context.instructions}
        </ModalFacelift>
      )}
      {!!context?.settings && (
        <ModalFacelift
          isOpen={context.settingsVisible}
          onEscapeKeyDown={context.toggleSettings}
          onPointerDownOutside={context.toggleSettings}
          onClose={context.toggleSettings}
        >
          {context.settings}
        </ModalFacelift>
      )}
    </>
  );
}

export function RateableTestIconWithoutTestData({
  className,
  rating,
  icon: Icon,
}: {
  rating: number;
  className?: string;
  icon?: React.FunctionComponent<{ className?: string }>;
}) {
  const hasStarted = false;
  const rated = rating !== 0;
  const failure = rating === 1 || rating === 2;

  if (!Icon) {
    console.warn(`No icon provided for rating ${rating}`);
    return null;
  }

  return (
    <div
      aria-hidden
      className={clsx(
        "relative flex h-8 min-h-8 w-8 min-w-8 items-center justify-center rounded-full",
        {
          "bg-missilegreen/20 border border-missilegreen": hasStarted && !rated,
          "bg-missilesurfacedark": !hasStarted && !rated,
          "bg-missilegreen": rated,
        },
        className,
      )}
    >
      <Icon
        className={clsx("h-5 w-5", {
          "text-missilegreen": hasStarted && !rated,
          "text-missilegray2 dark:text-white": !hasStarted && !rated,
          "text-white": rated,
        })}
      />
      {failure ? <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-orange-600" /> : null}
    </div>
  );
}

function RateableTestIconWithTestData({
  rateableTestId,
  rateableTestTypeId,
  className,
  rating,
  icon,
}: {
  rateableTestId: number;
  rateableTestTypeId: number;
  rating: number;
  className?: string;
  icon?: React.FunctionComponent<{ className?: string }>;
}) {
  const lessonId = useContext(LessonContext).id;

  const t = useTranslation();
  const canMarkAsComplete = useCanMarkAsComplete() && rateableTestId;
  const dispatch = useSharedDispatch();
  const productId = useActiveProduct()?.id || 0;
  const hasStarted =
    useNumTestComponents({
      rateableTestId,
      rateableTestTypeId,
      lessonId,
    }).numRated > 0;

  const rated = rating !== 0;
  const failure = rating === 1 || rating === 2;

  let Icon = icon;

  if (!Icon && rateableTestTypeId) {
    Icon = RateableTestMetadata[rateableTestTypeId]?.icon;
  }

  if (!Icon) {
    console.warn(`No icon found for rateable test type ${rateableTestTypeId}`);
    return null;
  }

  const handleMarkAsComplete = () => {
    if (!canMarkAsComplete || !rateableTestId) {
      return;
    }

    if (rating === 0) {
      dispatch({
        type: "Lesson/REQUEST_RATE_TEST",
        payload: {
          productId,
          rateableTestId: rateableTestId,
          rating: 100,
          markComplete: true,
        },
      });
    } else {
      dispatch({
        type: "Lesson/REQUEST_RATE_TEST",
        payload: {
          productId,
          rateableTestId: rateableTestId,
          rating: 0,
          markComplete: false,
        },
      });
    }
  };

  const content = (
    <button type="button" onClick={handleMarkAsComplete}>
      <div
        aria-hidden
        className={clsx(
          "relative flex h-10 w-10 items-center justify-center rounded-full",
          {
            "bg-missilegreen/20 border border-missilegreen": hasStarted && !rated,
            "bg-missilesurfacedark": !hasStarted && !rated,
            "bg-missilegreen": rated,
          },
          className,
        )}
      >
        <Icon
          className={clsx("h-5 w-5", {
            "text-missilegreen": hasStarted && !rated,
            "text-missilegray2 dark:text-white": !hasStarted && !rated,
            "text-white": rated,
          })}
        />
        {failure ? <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-orange-600" /> : null}
      </div>
    </button>
  );

  if (!canMarkAsComplete) {
    return content;
  }

  return (
    <SimpleTooltip content={rating === 0 ? t("mark-as-complete") : t("mark-as-incomplete")} asChild>
      {content}
    </SimpleTooltip>
  );
}
