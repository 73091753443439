import { PromiseResolver, RoundedButton } from "@rocketlanguages/ui";
import { Fragment, type ReactNode, useCallback, useEffect, useRef, useState } from "react";
import API from "@rocketlanguages/shared/res/Api";
import { getStaticPageQuery } from "@rocketlanguages/shared/hooks/usePageQuery";
import { resolveAsyncResource } from "@rocketlanguages/shared/utils";
import { showSignupModal } from "../../../store/user/actions";
import useCountdown from "@rocketlanguages/shared/hooks/useCountdown";
import { useDispatch } from "react-redux";
import usePromise from "@rocketlanguages/shared/hooks/usePromise";
import { useLocalStorageWithTimeout } from "@rocketlanguages/shared/hooks/useLocalStorageWithTimeout";
import { useStoreSelector } from "../../../store";
import useIsMackinUser from "@rocketlanguages/shared/hooks/useIsMackinUser";
import { useBannerStore } from "./Banner.store";
import { shallow } from "zustand/shallow";
import { CheckCircle as CheckCircleIcon, Xmark as XmarkIcon } from "iconoir-react";
import { clsx } from "clsx";
import type { Banner } from "@rocketlanguages/types";

function changeColorDarkness(hexColor: string, percent: number) {
  if (!hexColor.startsWith("#")) {
    console.warn(`hexColor is not a valid hex color, got: ${hexColor}.`);
    return "#000000";
  }

  let parsedColor = hexColor.replaceAll("#", "");

  if (parsedColor.length === 3) {
    parsedColor = `${parsedColor[0]}${parsedColor[0]}${parsedColor[1]}${parsedColor[1]}${parsedColor[2]}${parsedColor[2]}`;
  }

  const num = Number.parseInt(parsedColor, 16);
  const amt = Math.round(2.55 * percent);

  const clampToByte = (n: number) => {
    if (n > 255) {
      return 255;
    }

    if (n < 1) {
      return 0;
    }

    return n;
  };

  const r = clampToByte((num >> 16) + amt);
  const b = clampToByte(((num >> 8) & 0x00ff) + amt);
  const g = clampToByte((num & 0x0000ff) + amt);

  return "#" + (0x1000000 + r * 0x10000 + b * 0x100 + g).toString(16).slice(1);
}

const pageQuery = getStaticPageQuery();

function getBannerData(productId: number) {
  const bannerQueryParams: { banner_id?: number; promo_coupon?: string } = {};

  if (pageQuery.coupon) {
    bannerQueryParams.promo_coupon = String(pageQuery.coupon);
  }

  if (pageQuery.banner_id) {
    bannerQueryParams.banner_id = Number(pageQuery.banner_id);
  }

  /*
  return Promise.resolve({
    id: 1,
    is_promo: true,
    promo_override: false,
    title: "Banner Title",
    subtitle: "Banner subtitle",
    percent_off: 60,
    text_color: "red",
    background_color: "yellow",
    target_url: "https://www.rocketlanguages.com/pricing",
    image_url: null,
    expires_at_epoch_seconds: 1231231231233,
  });
  */
  return API.getJson(["v2/banners/product/{product}", { product: productId }], bannerQueryParams);
}

export function Banner({ productId }: { productId: number }) {
  const promise = useCallback(() => getBannerData(productId), [productId]);
  const { state } = usePromise(promise, { cacheKey: `banner-${productId}` });
  const isMackinUser = useIsMackinUser();
  const isLibraryUser = useStoreSelector((store) => store.user.isMultiuser);
  const { isBannerVisible, hideBanner } = useBannerStore(
    (s) => ({ isBannerVisible: s.visible, hideBanner: s.hide }),
    shallow,
  );

  const [hidePromoBanner, setHidePromoBanner] = useLocalStorageWithTimeout("hidePromoBanner", {
    timeoutInSeconds: 60 * 60 * 24 * 25, // hide for roughly one month
    defaultValue: false,
  });

  useEffect(() => {
    return () => {
      useBannerStore.setState({ visible: false });
    };
  }, []);

  useEffect(() => {
    const loadedState = resolveAsyncResource(state);
    const store = useBannerStore.getState();

    if (!loadedState) {
      store.reset();
      return;
    }

    // Minimum requirement is to have a banner title.
    const shouldHidePromoBanner = loadedState.is_promo && hidePromoBanner;
    const visible = !!loadedState.title && !shouldHidePromoBanner;

    useBannerStore.setState({
      visible,
      isPromo: loadedState.is_promo,
      pricingPageUrl: loadedState.target_url,
      modalImageUrl: loadedState.modal_image_url,
      expiresAtEpochSeconds: loadedState.expires_at_epoch_seconds,
      subtitle: loadedState.subtitle ?? undefined,
      buttonText: loadedState.button_text,
    });
  }, [state, hidePromoBanner]);

  if (!isBannerVisible || isMackinUser || isLibraryUser || !productId) {
    return null;
  }

  return (
    <PromiseResolver state={state} renderLoading={false}>
      {(banner) => {
        return (
          <InnerBanner
            banner={banner}
            onHide={() => {
              if (banner.is_promo) {
                setHidePromoBanner(true);
                hideBanner();
              }
            }}
          />
        );
      }}
    </PromiseResolver>
  );
}

function InnerBanner({ banner, onHide }: { banner: Banner; onHide(): void }) {
  const imageUrl = banner.image_url;
  const backgroundColor = banner.background_color;
  const textColor = banner.text_color;
  const buttonText = banner.button_text;
  const endTimeEpochSeconds = banner.expires_at_epoch_seconds;
  const subtitle = banner.subtitle;
  const [showBanner, setShowBanner] = useState(false);
  const scrollPositionRef = useRef(0);

  useEffect(() => {
    const scrollHandler = () => {
      const previousScrollPosition = scrollPositionRef.current;
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > 500 && currentScrollPosition > previousScrollPosition) {
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
      scrollPositionRef.current = currentScrollPosition;
    };

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <div
      className={clsx(
        "fixed left-0 right-0 z-10 flex h-[var(--banner-height)] w-full items-center transition-all duration-500",
        showBanner ? "top-0" : "top-[-100%]",
      )}
      style={{
        backgroundColor: `${backgroundColor}`,
        backgroundSize: "contain",
        color: textColor,
      }}
    >
      <BannerLink href={banner.target_url}>
        <div className="flex w-full items-center justify-between px-4">
          <div className="flex justify-start gap-4">
            <div className="flex-none">
              <img src={imageUrl} alt={buttonText} />
            </div>
            <div className="text-missilesurfacedark hidden items-center gap-4 text-sm xl:flex">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="flex-none">
                    <CheckCircleIcon strokeWidth={2} />
                  </span>
                  <span>The gold standard in language courses</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="flex-none">
                    <CheckCircleIcon strokeWidth={2} />
                  </span>
                  <span>One payment for 24/7 lifetime access</span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="flex-none">
                    <CheckCircleIcon strokeWidth={2} />
                  </span>{" "}
                  <span>High quality, in-depth courses that work</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="flex-none">
                    <CheckCircleIcon strokeWidth={2} />
                  </span>{" "}
                  <span>No risk 60-day money back guarantee</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-4">
              <div
                className="hidden h-16 w-auto flex-none flex-col items-center justify-center overflow-hidden rounded-2xl p-4 md:flex"
                style={{ backgroundColor: changeColorDarkness(backgroundColor, -20) }}
              >
                {endTimeEpochSeconds ? <BannerCountdown endTimeEpochSeconds={endTimeEpochSeconds} /> : null}
                {subtitle && <span className="text-nowrap text-xs" dangerouslySetInnerHTML={{ __html: subtitle }} />}
              </div>
              <div className="flex-none">
                <RoundedButton
                  type="button"
                  className="bg-missileaccent hover:bg-missileaccent/90 flex-col font-semibold text-white"
                >
                  <span>{buttonText}</span>
                </RoundedButton>
              </div>
            </div>
          </div>
        </div>
      </BannerLink>
      <div className={clsx("pr-4", !banner.is_promo && "hidden")}>
        <button
          aria-label="Close"
          style={{ color: textColor }}
          className="flex size-11 max-h-11 min-h-11 min-w-11 max-w-11 items-center justify-center"
          onClick={onHide}
        >
          <XmarkIcon strokeWidth={2} />
        </button>
      </div>
    </div>
  );
}

function BannerLink(props: { children: ReactNode; href: string }) {
  const dispatch = useDispatch();
  const isGuest = useStoreSelector((store) => store.user.isGuest);

  if (isGuest) {
    return <button onClick={() => dispatch(showSignupModal())}>{props.children}</button>;
  }

  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer" className="block w-full">
      {props.children}
    </a>
  );
}

function BannerCountdown({ endTimeEpochSeconds }: { endTimeEpochSeconds: number }) {
  const { isRunning, hoursMinutesSeconds } = useCountdown(endTimeEpochSeconds);

  const units = ["days", "hours", "mins", "secs"];
  if (!isRunning) {
    return null;
  }

  return (
    <div className="flex">
      {units.map((key, i) => (
        <Fragment key={key}>
          <div className="w-8">
            <div className="w-full text-center">
              <div className="h-6 text-2xl font-bold">
                {`${hoursMinutesSeconds[key as keyof typeof hoursMinutesSeconds]}`.padStart(2, "0")}
              </div>
              <span className="text-xs">{key}</span>
            </div>
          </div>
          {i < units.length - 1 && (
            <div>
              <div className="flex h-6 items-center justify-center px-2 text-2xl font-bold">
                <span>:</span>
              </div>
              <span className="invisible text-xs">{key}</span>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}
