import * as constants from "./constants";
import * as lessonActions from "./actions";
import * as preferenceActions from "../preferences/actions";
import * as userActions from "../user/actions";

import { SagaReturnType, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { activeProductSelector, authSelector, userSelector } from "../selectors";

import API from "../../res/Api";
import { ActionType } from "typesafe-actions";
import Courses from "../../res/courses";
import { SharedRootState } from "../types";
import type { UserComponentRatingEntity } from "@rocketlanguages/types";
import handleRequestError from "../../utils/handleRequestError";

const userStatsSelector = (state: SharedRootState) => state.user.stats;

export function getTestRating(props: { componentRatings: UserComponentRatingEntity[]; totalComponents: number }) {
  const { componentRatings, totalComponents } = props;
  const totalScore = componentRatings.reduce((previousValue, currentRating) => previousValue + currentRating.value, 0);
  const testRating = Math.floor(totalScore / totalComponents);
  return testRating;
}

/**
 * Resets a phrase test
 */
function* asyncResetTest(action: ActionType<typeof lessonActions.asyncResetTest>) {
  const { rateableTestId, lessonId } = action.payload;
  try {
    const activeProduct: ReturnType<typeof activeProductSelector> = yield select(activeProductSelector);

    if (!activeProduct) {
      throw new Error("No active product/lesson found");
    }

    // Reset test rating
    yield put(lessonActions.resetTestRating({ productId: activeProduct.id, lessonId, rateableTestId }));

    const user: ReturnType<typeof userSelector> = yield select(userSelector);
    if (!user.profile || !user.profile.hash) {
      throw new Error("No user found");
    }

    const resetTest = () =>
      API.get(["v2/reset-test/{rateableTest}", { rateableTest: rateableTestId }], { productId: activeProduct.id });

    // Reset request
    yield call(resetTest);

    // Success resetting test
    yield put(lessonActions.resetTestSuccess(rateableTestId));
  } catch (e) {
    yield put(lessonActions.resetTestFail(rateableTestId));
  }
}

/**
 * Performs a request to rate the transcript
 * Note: optimistically updates the rating in reducer.ts@RATE_PLAY_IT
 */
function* ratePlayIt(action: ActionType<typeof lessonActions.ratePlayIt>) {
  try {
    const request = () =>
      API.put([
        "v2/transcripts/{transcript}/characters/{character}/rate/{value}",
        {
          transcript: action.payload.transcriptId,
          character: action.payload.characterId,
          value: action.payload.rating,
        },
      ]);
    yield call(request);
  } catch (e) {
    console.log(e);
    // TODO: Undo optimisitic update
    // yield put(lessonActions.ratePlayItFail, transcriptId, characterId)
  }
}

/**
 * Rates a test after the test has completed (e.g. Quiz)
 */
function* requestRateTest(action: ActionType<typeof lessonActions.requestRateTest>) {
  const user: ReturnType<typeof userSelector> = yield select(userSelector);
  if (!user.profile || !user.profile.hash) {
    // yield navigate, display logged out
    return;
  }

  const { productId, rateableTestId, rating } = action.payload;

  try {
    // [Client side] Rate test. Optimistic update.
    yield put(lessonActions.rateTest({ rateableTestId, rating, markComplete: action.payload.markComplete }));
    // [Server side] Rate test
    const rateTestRequest = () =>
      API.get(
        [
          "v2/rate-test/{rateableTest}/{value}",
          {
            rateableTest: rateableTestId,
            value: rating,
          },
        ],
        { productId, markComplete: action.payload.markComplete ? 1 : 0 },
      );
    yield call(rateTestRequest);
  } catch (error) {
    // [Client side] Revert rating of test to 0.
    yield put(lessonActions.rateTest({ rateableTestId, rating: 0 }));
    console.warn(error);
  }
}

function* rateLesson(action: ActionType<typeof lessonActions.rateLesson>) {
  const { lessonId, rating } = action.payload;
  try {
    const rateLessonRequest = () =>
      API.put(
        [
          "v2/lesson/{lesson}/rate",
          {
            lesson: lessonId,
          },
        ],
        {
          value: rating,
        },
      );

    // Complete the lesson.
    yield call(rateLessonRequest);

    const isDone = Boolean(rating > 0);
    yield put(lessonActions.updateLessonCompletion(lessonId, isDone));
  } catch (e: any) {
    console.log(Object.keys(e), Object.values(e));
    // yield error
  }
}

/**
 * "Lesson/REQUEST" is dispatched as soon as a user visits the Lesson Screen.
 */
function* asyncRequestLesson(action: ActionType<typeof lessonActions.asyncRequestLesson>) {
  const { lessonId, productId } = action.payload;

  /*
  // Get lesson and rateable test data at the same time
  const dashboard: ReturnType<typeof dashboardSelector> = yield select(dashboardSelector);

  // Get dashboard data if user has visited the lesson directly
  if (!dashboard.products[productId]) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    yield call(asyncDashboardRequest, dashboardActions.asyncDashboardRequest({ productId, timezone }));
  }
  */
  yield put(lessonActions.requestStart({ lessonId }));

  try {
    const request = () =>
      API.get(["v2/product/{product}/lesson/{lesson}", { product: productId, lesson: lessonId }], {
        v: 2,
      });

    const response: SagaReturnType<typeof request> = yield call(request);

    yield put(
      lessonActions.requestSuccess({
        lessonId,
        productId,
        payload: response.data,
      }),
    );
  } catch (error: any) {
    yield put(
      lessonActions.requestFail({
        lessonId,
        error: {
          code: error.response?.data?.code || "",
          message: error.response?.data?.message || "",
          status: error.response?.status || 0,
        },
      }),
    );
    // shows toast if "error.response.data.message" is set
    yield handleRequestError(error);
  }
}

/**
 * Same as asyncRequestLesson but product agnostic
 */
function* asyncAdminRequestLesson(action: ActionType<typeof lessonActions.asyncRequestLesson>) {
  const { lessonId } = action.payload;

  yield put(lessonActions.requestStart({ lessonId }));

  try {
    const request = () => API.get(["v2/admin/lesson/{lesson}", { lesson: lessonId }]);

    const response: SagaReturnType<typeof request> = yield call(request);

    const { course, ...rest } = response.data.entities;

    const payload = {
      ...response.data,
      entities: {
        ...rest,
        courses: {
          [lessonId]: course,
        },
      },
    };

    // Then set the lesson
    yield put(
      lessonActions.adminRequestSuccess({
        lessonId,
        // @ts-ignore "courses" is added to the payload for no clear reason other than to link the course to the lesson
        payload,
      }),
    );

    // Manually update active course
    const fullCourse = Courses.find((_course) => _course.id === course.id);
    if (fullCourse) {
      yield put(preferenceActions.setActiveCourse(fullCourse));
    }
  } catch (error: any) {
    yield put(
      lessonActions.requestFail({
        lessonId,
        error: {
          code: error.response?.data?.code || "",
          message: error.response?.data?.message || "",
          status: error.response?.status || 0,
        },
      }),
    );
    // shows toast if "error.response.data.message" is set
    yield handleRequestError(error);
  }
}

const activeCourseIdSelector = (state: SharedRootState) =>
  state.preferences.activeCourse && state.preferences.activeCourse.id;

/**
 * Every time a user interacts with the Play button, points are added to the user
 */
function* requestAddPoints(action: ActionType<typeof lessonActions.requestAddPoints>) {
  // Get active course and lesson
  const activeCourseId: ReturnType<typeof activeCourseIdSelector> = yield select(activeCourseIdSelector);
  const auth: ReturnType<typeof authSelector> = yield select(authSelector);

  // very unlikely case that the user does not have an active course or lesson set.
  if (!activeCourseId) {
    return;
  }

  try {
    const params: any = {
      courseId: activeCourseId,
      ...action.payload,
    };
    if (auth.status !== "loggedin") {
      console.warn("[requestAddPoints] Not logged in.", params);
      return;
    }
    const addPointsRequest = () => API.post("v2/points/add", params);
    const response: SagaReturnType<typeof addPointsRequest> = yield call(addPointsRequest);
    const { data } = response.data;

    if (data.rankUpgrade?.currentRank) {
      // Rank upgrade notification
      yield put(userActions.showRankUpgradeNotification(data.rankUpgrade.currentRank));
    }

    const userStats: ReturnType<typeof userStatsSelector> = yield select(userStatsSelector);

    yield put(
      userActions.updateStats({
        ...userStats,
        dailyPoints: userStats.dailyPoints + data.awardedPoints,
        longestStreak: data.streaks.longestStreak,
        currentStreak: data.streaks.currentStreak,
        position: data.userPosition,
        totalPositions: data.totalPositions,
        totalPoints: userStats.totalPoints + data.awardedPoints,
      }),
    );
  } catch (error) {
    console.warn("[requestAddPoints]", error);
    // Typically 422 (Unprocessable) error
  }
}

// Any watchers go in here. They get forked in the Root Saga
export default [
  takeEvery(constants.ASYNC_REQUEST_LESSON, asyncRequestLesson),
  takeEvery(constants.ASYNC_ADMIN_REQUEST_LESSON, asyncAdminRequestLesson),
  // The user can interact with multiple tests and reset
  takeEvery(constants.ASYNC_RESET_TEST, asyncResetTest),
  takeLatest(constants.RATE_PLAY_IT, ratePlayIt),
  takeLatest(constants.REQUEST_RATE_TEST, requestRateTest),
  takeLatest(constants.RATE_LESSON, rateLesson),
  takeLatest(constants.REQUEST_ADD_POINTS, requestAddPoints),
];
