import { useEffect, useRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import ReactDOM from "react-dom";
import classes from "./Modal.module.scss";
import { clsx } from "clsx";
import { FaXmark } from "react-icons/fa6";

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  hideCloseButton?: boolean;
  onEscapeKeyDown?: () => void;
  onPointerDownOutside?: () => void;
  onClose?: () => void;
  className?: string;
};

export function ModalFacelift({
  children,
  isOpen,
  hideCloseButton,
  onEscapeKeyDown,
  onPointerDownOutside,
  onClose,
  className,
}: ModalProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }

    return () => {
      enablePageScroll();
    };
  }, [isOpen]);

  useEffect(() => {
    const onKeyPress = (ev: KeyboardEvent) => {
      if (ev.key === "Escape" && isOpen) {
        onEscapeKeyDown?.();
      }
    };

    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [isOpen, onEscapeKeyDown]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={modalRef} tabIndex={-1} className="fixed z-50">
      <div
        ref={overlayRef}
        className={clsx(
          "fixed inset-0 flex items-center justify-center overflow-auto bg-black/75",
          !isOpen ? "pointer-events-none" : "pointer-events-auto",
          classes.overlay,
        )}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            onEscapeKeyDown?.();
          }
        }}
        onClick={(e) => {
          if (overlayRef.current === e.target) {
            onPointerDownOutside?.();
          }
        }}
      >
        <div
          className="z-[999] max-h-screen min-w-[100%] p-4 sm:min-w-[50%] sm:max-w-screen-sm lg:min-w-[40%] lg:max-w-screen-md xl:min-w-[30%]"
          aria-modal
          aria-haspopup
        >
          <div className={clsx("relative overflow-hidden rounded-3xl bg-surface2 p-12 shadow-xl", className)}>
            {!hideCloseButton ? (
              <button type="button" aria-label="Close" className="absolute right-6 top-6" onClick={() => onClose?.()}>
                <FaXmark className="size-5" />
              </button>
            ) : null}
            {children}
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("body") as Element,
  );
}
