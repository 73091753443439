import type { LessonComponent, TranscriptComponent } from "@rocketlanguages/types";
import { useContext, useMemo, useState } from "react";

import Instructions from "./includes/Instructions";
import { PlayItContext } from "./includes/context";
import PlayItHeader from "./PlayItHeader";
import PlayItLines from "./PlayItLines";
import PlaybackButtons from "./PlaybackButtons";
import RateableTestUI from "../../../RateableTests/RateableTestUI";
import Settings from "./includes/Settings";
import { getPlayableCharacters } from "./includes/utils";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import { useSharedSelector } from "../../../../store";

interface Props {
  lessonComponent: LessonComponent<"transcript", TranscriptComponent>;
}

export default function PlayIt(props: Props) {
  const { lessonComponent } = props;
  const playitContext = useContext(PlayItContext);
  if (!playitContext) {
    throw new Error("PlayItContext not found. Please wrap in PlayItProvider");
  }
  const { state } = playitContext;
  const activeCourse = useActiveCourse();

  /** If true, displays a list of settings to show/hide writing systems */
  const [displaySettings, setDisplaySettings] = useState(false);

  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);

  // show draft lines if debug mode enabled or on admin domain
  const filteredLines = useMemo(() => {
    if (debugEnabled || (typeof window !== "undefined" && window.location.hostname === "staff.rocketlanguages.com"))
      return lessonComponent.component.lines;
    return lessonComponent.component.lines.filter((_line) => !!_line.status);
  }, [debugEnabled, lessonComponent.component.lines]);

  const activeCourseSlug = activeCourse ? activeCourse.slug : "";

  const characters = useMemo(() => getPlayableCharacters(filteredLines) ?? [], [filteredLines]);

  function getCharacterNameById(id: number) {
    const char = characters.find((c) => c.id === id);
    if (char) {
      return char.name;
    }
    return "Unknown";
  }

  return (
    <div className="rounded-2xl border border-missilestroke p-4">
      <RateableTestUI.Container
        id={`role-playing-${lessonComponent.component.id}`}
        instructions={<Instructions />}
        settings={<Settings lessonComponent={lessonComponent} />}
      >
        <PlayItHeader
          characterName={getCharacterNameById(Number(state.characterId))}
          componentId={lessonComponent.component.id}
          onToggleDisplaySettings={() => setDisplaySettings((state) => !state)}
          displaySettings={displaySettings}
        />
        <RateableTestUI.Body>
          <div className="pb-4">
            <PlaybackButtons
              characters={characters}
              numLines={filteredLines.length}
              componentId={lessonComponent.component.id}
            />
          </div>
          <PlayItLines lines={filteredLines} activeCourseSlug={activeCourseSlug} />
          {state.secondsCounter ? (
            <div className="absolute inset-0 bg-black bg-opacity-50 text-center text-white">
              <div className="mt-8 text-6xl">{state.secondsCounter}</div>
            </div>
          ) : null}
        </RateableTestUI.Body>
      </RateableTestUI.Container>
    </div>
  );
}
