import { RatingCircle, RoundedButton } from "@rocketlanguages/ui";
import type { Character, TranscriptRatings } from "@rocketlanguages/types";
import { PlayItContext } from "./includes/context";
import { actions as playItActions } from "./includes/usePlayIt";
import { useContext, useEffect, useMemo, useState } from "react";
import AudioContext from "../../../../ui/Audio/AudioContext";
import { clsx } from "clsx";
import usePlayItRatings from "../../../../hooks/usePlayItRatings";
import useTranslation from "../../../../hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";
import usePlayItComponents from "../../../../hooks/usePlayItComponents";
import LessonContext from "../../../../context/LessonContext";

interface Props {
  characters: Character[];
  numLines: number;
  componentId: number;
}

export default function PlaybackButtons({ characters, numLines, componentId }: Props) {
  const t = useTranslation();
  const { dispatch, state } = useContext(PlayItContext)!;
  const audioContext = useContext(AudioContext);
  const lessonId = useContext(LessonContext).id;
  const location = useLocation();
  const navigate = useNavigate();

  const playItComponents = usePlayItComponents(lessonId);

  const playItId = useMemo(() => {
    if (playItComponents.length <= 1) {
      return "#role-playing";
    }
    // Travelogue components may have multiple play it components
    const playItNumber = playItComponents.findIndex((lc) => lc.component.id === componentId) + 1;
    return `#role-playing-#${playItNumber}`;
  }, [playItComponents, componentId]);

  const playItRatings = usePlayItRatings(componentId);

  /** Shows or hides character buttons */
  const [displayCharacterButtons, setDisplayCharacterButtons] = useState(hasRatings(playItRatings));

  // Effect to show the character buttons if transcript ratings are updated
  useEffect(() => {
    if (!displayCharacterButtons && hasRatings(playItRatings)) {
      setDisplayCharacterButtons(true);
    }
  }, [displayCharacterButtons, playItRatings]);

  useEffect(() => {
    if (state.status === "active") {
      audioContext.activePlayer?.pause();
    } else if (state.status === "finished" || state.status === "aborted") {
      // this is to re-enable the popups while doing this activity
      navigate(location.pathname, { replace: true, preventScrollReset: true });
    }
  }, [audioContext.activePlayer, state.status, location.pathname, navigate]);

  /**
   * Dispatches a `start` action to the PlayIt context
   *
   * @see PlayIt.tsx for state reducer updates
   */
  function startPlayIt(characterId: number) {
    // play-it-${lessonComponent.component.id}
    // this is to be able to block the popup while doing this activity
    navigate(playItId, { replace: true, relative: "path", preventScrollReset: true });
    dispatch(
      playItActions.start({
        componentId,
        characterId,
        numLines,
      }),
    );
  }

  return (
    <div className="grid w-full grid-cols-2 gap-2 lg:grid-cols-3 lg:items-end print:hidden">
      <RoundedButton
        className={clsx(
          "hover:bg-missilebrand/90 order-2 col-span-2 w-full whitespace-nowrap bg-missilebrand px-8 font-semibold text-white lg:order-none lg:col-span-1",
          state.status === "active" && state.mode !== "listen" && "opacity-50",
        )}
        onClick={() => {
          if (state.mode === "listen" && state.status === "active") {
            dispatch({ type: "ABORT" });
          } else {
            // this is to be able to block the popup while doing this activity
            navigate(playItId, { replace: true, relative: "path" });
            dispatch(
              playItActions.listenConversation({
                componentId,
                numLines,
              }),
            );
          }
        }}
      >
        {state.mode === "listen" && state.status === "active" ? t("stop") : t("play-conversation")}
      </RoundedButton>
      <div className="col-span-2 flex w-full items-center gap-2">
        {characters.map((char) => {
          const rating = playItRatings[char.id];

          return (
            <div key={`char.${char.id}`} className="w-full space-y-2">
              <div className="flex flex-1 justify-center">
                <RatingCircle percentage={rating || 0} />
              </div>
              <RoundedButton
                className={clsx(
                  "dark:hover:bg-missilebrand/90 w-full whitespace-nowrap border-2 border-missilebrand px-8 font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white",
                  state.mode === "listen" && state.status === "active" && "opacity-50",
                  state.status === "active" && (state.characterId !== char.id || state.mode !== "test") && "opacity-50",
                )}
                onClick={() => {
                  audioContext.activePlayer?.pause();
                  if (state.status === "active" && state.characterId === char.id) {
                    dispatch({ type: "ABORT" });
                  } else {
                    startPlayIt(char.id);
                  }
                }}
              >
                {state.status === "active" && state.characterId === char.id && state.mode === "test"
                  ? t("stop")
                  : t("play-as-{hostname}", { hostname: char.name })}
              </RoundedButton>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function hasRatings(ratings?: TranscriptRatings) {
  if (!ratings || Array.isArray(ratings) || typeof ratings !== "object") {
    return false;
  }

  return Object.keys(ratings).length > 0;
}
