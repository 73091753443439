import { ReactNode, createContext, useState } from "react";

import type { SetStateFunction } from "@rocketlanguages/types";

type QuizState = "idle" | "started" | "revealed";

export const DrawItContext = createContext<
  | {
      showGrid: boolean;
      setShowGrid: SetStateFunction<boolean>;
      showShadow: boolean;
      setShowShadow: SetStateFunction<boolean>;
      enableHints: boolean;
      setEnableHints: SetStateFunction<boolean>;
      quizState: QuizState;
      setQuizState: SetStateFunction<QuizState>;
    }
  | undefined
>(undefined);

function DrawItContextProvider({ children }: { children: ReactNode }) {
  const [showGrid, setShowGrid] = useState(false);
  const [showShadow, setShowShadow] = useState(true);
  const [enableHints, setEnableHints] = useState(true);
  const [quizState, setQuizState] = useState<QuizState>("idle");

  return (
    <DrawItContext.Provider
      value={{
        showGrid,
        setShowGrid,
        showShadow,
        setShowShadow,
        enableHints,
        setEnableHints,
        quizState,
        setQuizState,
      }}
    >
      {children}
    </DrawItContext.Provider>
  );
}

export default DrawItContextProvider;
