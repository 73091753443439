import { RoundedButton } from "@rocketlanguages/ui";
import { Settings as SettingsIcon } from "iconoir-react";
import { FaMicrophone } from "react-icons/fa";

export default function Instructions() {
  return (
    <div className="mx-2 mb-2 sm:mx-8 sm:mb-8">
      <h4 className="mb-2">Role Playing</h4>
      <div className="mb-4">
        <p className="mb-2">
          The Role Playing activity is designed to help you practice having a conversation in a pressure-free
          environment. This will get you ready for when you have to do it in the real world!
        </p>
      </div>
      <h4 className="mb-2">Instructions</h4>
      <div className="mb-4 space-y-2">
        <p className="space-x-4">
          <span className="inline-block">
            <RoundedButton className="whitespace-nowrap border-2 border-missilebrand px-8 py-4 font-semibold text-missilebrand hover:bg-missilebrand hover:text-white">
              Select
            </RoundedButton>
          </span>
          <span>which character you want to play.</span>
        </p>
        <p className="space-x-4">
          <span className="inline-block">
            <button className="flex size-10 min-h-10 min-w-10 items-center justify-center rounded-xl bg-missileaccent text-white">
              <FaMicrophone />
            </button>
          </span>
          <span>RECORD yourself taking part in the conversation.</span>
        </p>
        <p className="space-x-4">
          <span className="inline-block">
            <button className="rounded-xl border border-missilestroke bg-missilesurfacelight p-2 hover:bg-missilesurfacedark">
              <SettingsIcon />
            </button>
          </span>
          <span>Use the SETTINGS to make Role Playing more difficult.</span>
        </p>
      </div>
    </div>
  );
}
