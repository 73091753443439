import "./index.css";
import "@rocketlanguages/ui/styles/base-tailwind.scss";
import "./polyfills";

import * as Sentry from "@sentry/react";

import App from "./App";
import { createRoot } from "react-dom/client";

if (import.meta.env.PROD) {
  console.warn("Initializing Sentry");
  Sentry.init({
    dsn: "https://00b23450b42a4bd796332d2623a9716c@o59093.ingest.sentry.io/5428934",
    environment: "production",
    ignoreErrors: [
      // Unknown "blob" error on MacOS that caused thousands of errors
      /TypeError: null is not an object \(evaluating '[a-z]+.data\[0\]'\)/,
      // audio/video
      "InvalidStateError: The object is in an invalid state.",
      // Axios
      "Request failed with status code 500",
      "Network Error",
      "timeout of 30000ms exceeded",
      // CloudFront
      "Unexpected token '<'",
      // Rocket Record
      "ERROR_NO_SOUND",
      /NotSupportedError:.*/,
      /AbortError:.*/,
      // Olark
      "Object expected",
      "Object doesn't support this action",
      "NS_ERROR_NOT_INITIALIZED",
      /clearTimeout is not a function./,
      /Extension context invalidated./,
      "Error setting badge. Message: can't access dead object",
      // Other
      "Out of memory", // Caused a 50k spike in errors from a single user..
      /Loading chunk */, // General chunk loading error
    ],
  });
}

const rootElement = document.getElementById("root");

const root = createRoot(rootElement as HTMLElement);
root.render(<App />);
