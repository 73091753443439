/**
 * Shows Red/Green/Black text from a diff object (generated by the "diff" library)
 */

import clsx from "clsx";
import type * as jsdiff from "diff";

type TextDiffProps = {
  writingDiff: jsdiff.Change[];
  isRTL?: boolean;
};

/**
 * Renders the difference between what the user had written and the actual phrase
 */
export function TextDiff({ writingDiff, isRTL }: TextDiffProps) {
  if (!writingDiff) {
    return null;
  }

  const arr = isRTL ? [...writingDiff].reverse() : writingDiff;

  return (
    <>
      {arr.map((part, i) => (
        <TextElement part={part} isRTL={isRTL} key={i.toString()} />
      ))}
    </>
  );
}

function TextElement({ part, isRTL }: { part: jsdiff.Change; isRTL?: boolean }) {
  if (part.added) {
    return null;
  }

  if (part.removed) {
    return <span className={clsx(!isRTL && "font-semibold", "text-missileaccent")}>{part.value}</span>;
  }

  return <span className={clsx(!isRTL && "font-semibold", "text-missilegreen")}>{part.value}</span>;
}

export function WriteItTextDiff({ writingDiff, isRTL }: TextDiffProps) {
  if (!writingDiff) {
    return null;
  }

  const arr = isRTL ? [...writingDiff].reverse() : writingDiff;

  return (
    <>
      {arr.map((part, i) => (
        <WriteItTextElement part={part} key={i.toString()} />
      ))}
    </>
  );
}

function WriteItTextElement({ part }: { part: jsdiff.Change }) {
  if (part.added) {
    return <span className="text-text2">{part.value}</span>;
  }

  if (part.removed) {
    return <span className="text-rocketred line-through">{part.value}</span>;
  }

  return <span className="text-gray-400">{part.value}</span>;
}
