import { Centered, CircularProgress, ErrorMessage, RoundedButton, Button } from "@rocketlanguages/ui";
import { type ReactNode, useContext, useEffect, useMemo, useRef } from "react";

import AudioContext from "../../ui/Audio/AudioContext";
import RateableTestContext from "./includes/context";
import RateableTestUI from "./RateableTestUI";
import { ReinforcementContext } from "../../context/ReinforcementContext";
import { requestRateTest } from "../../store/lesson/actions";
import { useDispatch } from "react-redux";
import type usePhraseTest from "../../hooks/usePhraseTest/usePhraseTest";
import { useSharedSelector, useSharedStore } from "../../store";
import useTranslation from "../../hooks/useTranslation";
import API from "../../res/Api";
import { getNextRateableTest } from "../../utils";
import LessonContext from "../../context/LessonContext";
import { RateableTestTypes } from "../../utils/constants";

type Props = {
  children: ReactNode;
  phraseTest: ReturnType<typeof usePhraseTest>;
  testName: string;
  testSubheading?: string;
  testTypeId: number;
  instructions?: JSX.Element;
  settings?: JSX.Element;
};

/**
 * - Provides context data to child elements (through `PhraseTestContext`)
 * - UI wrapper for title, loading states, error states
 * */
export default function RateableTest(props: Props) {
  const { computed } = props.phraseTest;

  const phraseTestRef = useRef<HTMLDivElement>(null);

  const phraseTestContextValue = useMemo(
    () => ({ phraseTest: props.phraseTest, testContainerRef: phraseTestRef, testTypeId: props.testTypeId }),
    [props.phraseTest, props.testTypeId],
  );

  return (
    <RateableTestUI.Container
      rateableTestTypeId={props.testTypeId}
      instructions={props.instructions}
      settings={props.settings}
      ref={phraseTestRef}
    >
      <RateableTestContext.Provider value={phraseTestContextValue}>
        <RateableTestUI.Header
          testName={props.testName}
          testSubheading={props.testSubheading}
          rateableTestId={props.phraseTest.rateableTestId}
          rateableTestTypeId={props.testTypeId}
          ratingLevel={computed.ratingLevel}
          position={computed.position}
          total={computed.totalComponents}
        />
        <PhraseTestContent testName={props.testName} testTypeId={props.testTypeId}>
          {props.children}
        </PhraseTestContent>
      </RateableTestContext.Provider>
    </RateableTestUI.Container>
  );
}

/** Controls rendering of extra tests */
/*
interface RegisterExtraTestProps {
  children: () => JSX.Element;
  phraseTest: ReturnType<typeof usePhraseTest>;
}

function RegisterExtraTest({ phraseTest, children }: RegisterExtraTestProps) {
  const rateableTest = useSharedSelector((store) => store.lesson.entities.rateable_tests[phraseTest.rateableTestId]);
  const isValidTest = phraseTest.components.phrases.length >= 5;

  if (rateableTest?.is_extra && !isValidTest) {
    return null;
  }

  return children();
}
*/

interface PhraseTestContentProps {
  testName: string;
  children: React.ReactNode;
  testTypeId: number;
}

/** Shows loading, error, retry UI before displaying the actual phrase test */
export function PhraseTestContent({ children, testName, testTypeId }: PhraseTestContentProps) {
  const t = useTranslation();
  const dispatch = useDispatch();

  const { components, state, status, methods, computed, rateableTestId } = useContext(RateableTestContext).phraseTest;
  const { activePhraseTest, setActivePhraseTest } = useContext(ReinforcementContext);
  const audioContext = useContext(AudioContext);
  const activeProduct = useSharedSelector((store) => store.preferences.activeProduct);
  const store = useSharedStore();
  const lessonId = useContext(LessonContext).id;

  // disable audio player when a test is started
  useEffect(() => {
    if (state.status === "started") {
      audioContext.activePlayer?.pause();
    }
    //eslint-disable-next-line
  }, [state.status]);

  useEffect(() => {
    if (activePhraseTest && state.status === "started" && activePhraseTest !== testTypeId) {
      methods.dispatch({ type: "RESET" });
    }
    //eslint-disable-next-line
  }, [activePhraseTest, testTypeId]);

  // While the request is still ongoing
  if (status === "loading" || status === "resetting") {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }

  // If there's an error
  if (status === "error") {
    return (
      <ErrorMessage
        title="Whoops!"
        message="Looks like something unexpected happened."
        actions={<Button color="primary">{t("reload")}</Button>}
      />
    );
  }

  // Test has completed
  if (computed.hasCompleted) {
    const numNonEasyPhrases = computed.ratings.hard.size + computed.ratings.good.size;

    const nextRateableTest = getNextRateableTest({ rateableTestId, lessonId, store });
    const localeKey = nextRateableTest ? RateableTestTypes[nextRateableTest.rateable_test_type_id]?.code : null;
    const nextTestName = localeKey ? t(localeKey) : null;

    return (
      <RateableTestUI.CompleteFacelift
        incorrectCount={numNonEasyPhrases}
        correctCount={computed.ratings.easy.size}
        nextActivityTitle={nextTestName ? `Next Activity: ${nextTestName}` : undefined}
        nextActivityHref={localeKey ? `#${localeKey}` : undefined}
        completedWithinSession={state.status === "complete"}
        onConfirmReset={methods.reset}
        testName={testName}
        onReviewHardPhrases={methods.redoNonEasyPhrases}
      />
    );
  }

  // Finished loading, no phrases or video components (for ASL) found
  if (components.phrases.length === 0 && components.videoComponents.length === 0) {
    return (
      <ErrorMessage
        title="Network Error"
        message="Looks like there was a problem loading the phrase set."
        actions={<Button color="primary">{t("reload")}</Button>}
      />
    );
  }

  if (components.testPhrases.length === 0 || (state.status !== "started" && state.mode !== "non_easy_components")) {
    const handleStartOrContinue = () => {
      if (activeProduct) {
        dispatch(
          requestRateTest({
            productId: activeProduct.id,
            rateableTestId: rateableTestId,
            rating: 0,
            markComplete: false,
          }),
        );
        methods.dispatch({ type: "START" });
        setActivePhraseTest(testTypeId);
      }
    };

    return (
      <div className="flex flex-1 items-center justify-center">
        <RoundedButton
          className="hover:bg-missileaccent/90 w-full max-w-60 bg-missileaccent font-semibold text-white"
          onClick={() => {
            API.post("v2/events/capture", {
              event: "test start",
              properties: {
                rateableTestId,
              },
            });
            handleStartOrContinue();
          }}
        >
          {computed.position === 0 ? t("get-started") : t("continue")}
        </RoundedButton>
      </div>
    );
  }

  return state.status === "started" ? <>{children}</> : null;
}
