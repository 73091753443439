import useEventListener from "./useEventListener";

type PhraseBoxHotkeyProps = {
  enabled: boolean;
  callbacks: {
    play: () => void;
    record: () => void;
    replay: () => void;
  };
}

export default function usePhraseBoxHotkey({ callbacks, enabled }: PhraseBoxHotkeyProps) {
  if (typeof document === "undefined") {
    return;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEventListener(
    "keydown",
    (e) => {
      if (e.code === "KeyP") {
        callbacks.play();
      } else if (e.code === "KeyR") {
        callbacks.record();
      } else if (e.code === "KeyL") {
        callbacks.replay();
      }
    },
    document,
    enabled,
  );
}
