import { useSharedDispatch } from "../store";
import { useApiUrl } from "../res/Api";
import { config } from "../utils/utils";
import { useState } from "react";

export default function StagingApiToggle() {
  const { mode, toggle } = useApiUrl();
  const dispatch = useSharedDispatch();
  const appMode = config("MODE", "production");
  const [show, setShow] = useState(true);

  if (appMode === "production" || !show) {
    return null;
  }

  return (
    <div className="pointer-events-none fixed left-0 right-0 top-0 z-[9999] flex justify-center">
      <div className="pointer-events-auto flex">
        <button
          type="button"
          title={`Toggle between ${appMode} & production APIs`}
          style={{
            color: mode === "production" ? "var(--color-rocketred)" : "#21dd1e",
          }}
          className="bg-gray-200 px-4 py-2 font-semibold hover:bg-gray-500"
          onClick={() => {
            toggle();
            dispatch({
              type: "Auth/LOGOUT",
              payload: false,
            });
          }}
        >
          {mode}
        </button>
        <button
          type="button"
          title="Close"
          onClick={() => setShow(false)}
          className="bg-gray-200 px-4 text-black hover:bg-gray-500"
        >
          x
        </button>
      </div>
    </div>
  );
}
