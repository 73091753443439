import type { LessonComponent, Phrase, TranscriptComponent as TranscriptComponentType } from "@rocketlanguages/types";

import PlayIt from "./PlayIt/PlayIt";
import PlayItProvider from "./PlayIt/includes/context";
import TranscriptLines from "./includes/TranscriptLines";
import API from "../../../res/Api";
import { getPlayableCharacters } from "./PlayIt/includes/utils";

export type Props = {
  lessonComponent: LessonComponent<"transcript", TranscriptComponentType>;
  /** Set when used in admin area, renders phrasebox within an editable wrapper instead */
  phraseboxWrapper?: (
    key: string | number,
    phrase: Phrase,
    component: JSX.Element,
    phraseIndex: number,
  ) => JSX.Element | null;
};

export default function TranscriptComponent(props: Props) {
  const { lessonComponent } = props;

  /** Users won't be graded on components with less than 4 lines, or if there less than 2 playable characters */
  const characters = getPlayableCharacters(lessonComponent.component.lines);
  const isValidPlayIt = lessonComponent.component.lines.length > 3 && !!characters;

  if (isValidPlayIt) {
    return (
      <PlayItProvider
        onStart={(transcriptId) => {
          API.post("v2/events/capture", {
            event: "playit start",
            properties: {
              transcriptId,
            },
          });
        }}
        onEnd={(state, transcriptId) => {
          if (state.status !== "finished" || state.mode !== "test") {
            return;
          }
          API.post("v2/events/capture", {
            event: "playit end",
            properties: {
              transcriptId,
            },
          });
        }}
        phraseboxWrapper={props.phraseboxWrapper}
      >
        <PlayIt lessonComponent={lessonComponent} />
      </PlayItProvider>
    );
  }

  return (
    <TranscriptLines
      componentId={props.lessonComponent.id}
      lines={lessonComponent.component.lines}
      phraseboxWrapper={props.phraseboxWrapper}
    />
  );
}
