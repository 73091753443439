import type { Phrase, TranscriptLine } from "@rocketlanguages/types";
import { PlayItContext } from "./includes/context";
import { actions as playItActions } from "./includes/usePlayIt";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ComponentStatusWrapper from "../../includes/ComponentStatusWrapper";
import type { RatedPhrase } from "../../../../hooks/useRocketRecord/types";
import Voice from "../../../Phrasebox/includes/RocketRecord/Voice";
import { clsx } from "clsx";
import { isMobileDevice } from "../../../../utils/browser";
import useGetter from "../../../../hooks/useGetter";
import { useSharedSelector } from "../../../../store";
import { Phrasebox, useWebRocketRecord } from "../../../PhraseboxFacelift/PhraseboxFacelift";
import AudioButton from "../../../Phrasebox/includes/AudioButton";
import { User as UserIcon } from "iconoir-react";
import { phraseStringTransformer } from "./includes/utils";
import { WritingSystemIds } from "../../../../utils/constants";

/** DOM ID applied to the container of a phrase line. Used for height calculation & scrolling */
const getPhraseLineDomId = (lineId: number) => `playit-phrase-${lineId}`;

export default function PlayItLines(props: { lines: TranscriptLine[]; activeCourseSlug: string }) {
  const PlayIt = useContext(PlayItContext);
  if (!PlayIt) {
    throw new Error("PlayItContext not found. Please wrap in PlayItProvider");
  }
  const phrases = useSharedSelector((store) => store.lesson.entities.phrases);
  /** Play It has started on this component */
  const isStarted = PlayIt.state.status === "active";
  const shouldScrollIntoView = isStarted || PlayIt.state.status === "countdown";
  const firstCharacter = props.lines.find((line) => line.character)?.character;

  useEffect(() => {
    // Height from the top is a bit too much on mobile
    if (shouldScrollIntoView && !isMobileDevice()) {
      const playItElement = document.getElementById(`play-it-${PlayIt.state.componentId}`);
      if (playItElement) {
        const scrollPosition = playItElement.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScrollIntoView]);

  return (
    <div className="overflow-hidden">
      <PlayItScroller lines={props.lines} index={PlayIt.state.index} isStarted={isStarted}>
        {props.lines.map((line, index) => {
          const phrase = phrases[line.phrase_id];
          if (!phrase) {
            return null;
          }

          const showCharacterName = props.lines[index - 1]?.character?.name !== line.character?.name;

          return (
            <PlayItLine
              key={line.id}
              lines={props.lines}
              line={line}
              activeCourseSlug={props.activeCourseSlug}
              index={index}
              showCharacterName={showCharacterName}
              isFirstCharacter={firstCharacter?.id === line.character?.id}
              phrase={phrase}
            />
          );
        })}
      </PlayItScroller>
    </div>
  );
}

function PlayItLine(props: {
  lines: TranscriptLine[];
  line: TranscriptLine;
  activeCourseSlug: string;
  index: number;
  showCharacterName: boolean;
  isFirstCharacter: boolean;
  phrase: Phrase;
}) {
  const PlayIt = useContext(PlayItContext);
  if (!PlayIt) {
    throw new Error("PlayItContext not found. Please wrap in PlayItProvider");
  }

  const PhraseboxElement = (
    <PlayItPhrasebox
      phrase={props.phrase}
      activeCourseSlug={props.activeCourseSlug}
      lines={props.lines}
      line={props.line}
      index={props.index}
    />
  );

  const characterName = props.line.character?.name;
  const avatarUrl = props.line.character?.avatar_url;

  return (
    <div
      key={props.line.id}
      id={getPhraseLineDomId(props.line.id)}
      className={clsx("flex flex-col space-y-2", props.isFirstCharacter ? "items-start" : "items-end")}
    >
      {props.showCharacterName ? (
        <div className="flex flex-row items-center gap-2">
          {avatarUrl ? (
            <img src={avatarUrl} alt={characterName} className="h-7 min-h-7 w-7 min-w-7 overflow-hidden rounded-lg" />
          ) : (
            <div className="flex h-7 min-h-7 w-7 min-w-7 items-center justify-center rounded-lg bg-missilesurfacedark text-missilegray2">
              <UserIcon />
            </div>
          )}
          <h4 className="text-sm">{characterName}</h4>
        </div>
      ) : null}
      <div className="w-full max-w-[650px]">
        {PlayIt.phraseboxWrapper ? (
          PlayIt.phraseboxWrapper(props.line.phrase_id, props.phrase, PhraseboxElement, props.index)
        ) : (
          <ComponentStatusWrapper draft={!props.line.status}>{PhraseboxElement}</ComponentStatusWrapper>
        )}
      </div>
    </div>
  );
}

export function PlayItPhrasebox(props: {
  phrase: Phrase;
  activeCourseSlug: string;
  lines: TranscriptLine[];
  line: TranscriptLine;
  index: number;
}) {
  const PlayIt = useContext(PlayItContext);
  if (!PlayIt) {
    throw new Error("PlayItContext not found. Please wrap in PlayItProvider");
  }

  const getCurrentPlayIt = useGetter(PlayIt);

  const active = PlayIt.state.status === "active" || PlayIt.state.status === "countdown";

  /** Play It has started on this component */
  const isStarted = PlayIt.state.status === "active";

  const onPlayFinish = useCallback(() => {
    const currentPlayIt = getCurrentPlayIt();
    const { characterId, mode, status, index: stateIndex } = currentPlayIt.state;

    const isCurrentLineActive = status === "active" && props.index === stateIndex;
    const isRecordLineOnTest = Boolean(props.line.character?.id === characterId) && mode === "test";

    if (!isCurrentLineActive || isRecordLineOnTest) {
      return;
    }

    if (mode === "listen") {
      const timeoutDuration =
        props.line.character?.id === props.lines[(props.index || 1) - 1]?.character?.id ? 750 : 250;
      setTimeout(() => {
        currentPlayIt.dispatch(playItActions.next());
      }, timeoutDuration);
    } else {
      setTimeout(() => {
        currentPlayIt.dispatch(playItActions.next());
      }, 750);
    }
  }, [getCurrentPlayIt, props.lines, props.line, props.index]);

  const onRecordFinish = useCallback(
    (params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => {
      const { result } = params;

      const currentPlayIt = getCurrentPlayIt();

      const { disableVoiceRecognition, characterId, mode, status, index: stateIndex } = currentPlayIt.state;

      if (
        Voice.isAnyKindOfRecognitionSupported() &&
        !disableVoiceRecognition &&
        (!result || result.ratingLevel === 0)
      ) {
        return;
      }

      /** Play It has started on this component */
      const isRecordLine = Boolean(
        props.line.character && props.line.character.id === characterId && mode !== "listen",
      );
      const isLineActive = status === "active" && props.index === stateIndex;
      if (isRecordLine && isLineActive) {
        currentPlayIt.dispatch(playItActions.recordFinish(params));
        setTimeout(() => {
          currentPlayIt.dispatch(playItActions.next());
        }, 750);
      }
    },
    [getCurrentPlayIt, props.line, props.index],
  );

  const isCurrentLineActive = isStarted && props.index === PlayIt.state.index;
  const isRecordLine = Boolean(
    props.line.character && props.line.character.id === PlayIt.state.characterId && PlayIt.state.mode !== "listen",
  );

  const initialResult = PlayIt.state.ratingsPerLine?.get(props.phrase.id);
  const isTest = PlayIt.state.mode === "test";
  const isListeningConversation = PlayIt.state.mode === "listen";

  const playOnMount = !isRecordLine && isCurrentLineActive;
  const recordOnMount = PlayIt.state.mode === "test" && isRecordLine && isCurrentLineActive;
  const playRecordingOnMount =
    PlayIt.state.mode === "playthrough" && isRecordLine && isCurrentLineActive && !!initialResult?.blobUrl;

  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);

  const rocketRecord = useWebRocketRecord({
    phrase: props.phrase,
    persistBlobs: true,
    initialResult: initialResult?.result,
    initialBlobUrl: initialResult?.blobUrl,
    onRecordFinish: onRecordFinish,
    disableVoiceRecognition: PlayIt.state.disableVoiceRecognition,
  });

  useEffect(() => {
    if (isCurrentLineActive && recordOnMount) {
      void rocketRecord.methods.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordOnMount, isCurrentLineActive]);

  const hasNotations = hasNotationsInPhrase(props.phrase);
  const hasMoreThanOneWritingSystem = props.phrase.strings.length > 2;

  const canRecord = props.phrase.disable_voice_recognition === 0;
  const shouldDisablePlayer =
    active && ((isTest && !isRecordLine) || (isListeningConversation && !isCurrentLineActive));
  const shouldHideRecordButton = active && ((isTest && !isRecordLine) || isListeningConversation);
  const shouldDisableRecordButton = active && isTest && isRecordLine && !isCurrentLineActive;

  return (
    <div className={clsx(hasNotations && "pt-6")}>
      <Phrasebox rocketRecord={rocketRecord}>
        <div className="relative">
          <div
            className={clsx(
              "rounded-2xl border-2 border-missilestroke p-4 dark:bg-missilesurfacelight",
              hasNotations && "rounded-tr-none",
            )}
          >
            {hasNotations ? (
              <Phrasebox.Notations onlyShowLiteralString={Boolean(!props.phrase.show_notations)} />
            ) : null}

            <Phrasebox.RowLayout>
              {playRecordingOnMount ? (
                <AudioButton
                  className="bg-missilesurfacedark text-missilebrand dark:text-white"
                  source={initialResult.blobUrl}
                  title="Play Recorded Audio"
                  disabled={shouldDisablePlayer}
                  onPlayFinish={onPlayFinish}
                  audioRef={audioPlayerRef}
                  playOnMount={playRecordingOnMount}
                />
              ) : (
                <Phrasebox.PlayButton
                  className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
                  disabled={shouldDisablePlayer}
                  audioRef={audioPlayerRef}
                  playOnMount={playOnMount}
                  onPlayFinish={onPlayFinish}
                  useDarkWaveform
                />
              )}

              <Phrasebox.StringsContainer>
                <Phrasebox.Strings filteredWritingSystemIds={[...PlayIt.filteredWritingSystems.values()]}>
                  {(phraseString, index, items) => {
                    const isLast = index === items.length - 1;
                    const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;

                    let text = phraseString.text;

                    const isTranslation =
                      phraseString.writing_system_id === WritingSystemIds.english &&
                      props.activeCourseSlug !== "english" &&
                      props.activeCourseSlug !== "ingles";

                    const isNativeTranslation =
                      props.activeCourseSlug === "ingles" &&
                      phraseString.writing_system_id === WritingSystemIds.spanish;

                    const shouldHideSecondWord = PlayIt.hideWords && !isTranslation && !isNativeTranslation;

                    if (shouldHideSecondWord) {
                      const transformer =
                        phraseStringTransformer[phraseString.writing_system_id] || phraseStringTransformer.default;
                      text = transformer(phraseString.text, phraseString.writing_system_id === WritingSystemIds.arabic);
                    }

                    return (
                      <div
                        className={
                          shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined
                        }
                      >
                        <Phrasebox.String {...phraseString} text={text} />
                      </div>
                    );
                  }}
                </Phrasebox.Strings>
              </Phrasebox.StringsContainer>
            </Phrasebox.RowLayout>

            {canRecord && !shouldHideRecordButton ? (
              <>
                <Phrasebox.RowLayout className="h-6 items-center py-0">
                  <Phrasebox.StoreRatingButton />
                  <div className="mb-2 flex-1 border-b border-b-missilestroke pb-2" />
                </Phrasebox.RowLayout>

                <Phrasebox.RowLayout className="items-center pt-2">
                  <Phrasebox.RecordButton
                    className="hover:bg-missileaccent/90 bg-missileaccent text-white"
                    disabled={shouldDisableRecordButton}
                  />

                  <Phrasebox.SpeechLayout className="flex-1">
                    <Phrasebox.Speech />
                    <Phrasebox.ErrorText />
                  </Phrasebox.SpeechLayout>

                  <Phrasebox.RecordPlaybackButton
                    className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
                    useDarkWaveform
                  />
                </Phrasebox.RowLayout>
                <Phrasebox.DebugPanel />
              </>
            ) : null}
          </div>
        </div>
      </Phrasebox>
    </div>
  );
}

/** Sets a margin-top offset based off the current index & phrase rect height */
function PlayItScroller({
  lines,
  index,
  isStarted,
  children,
}: {
  lines: TranscriptLine[];
  index: number;
  isStarted: boolean;
  children: React.ReactNode;
}) {
  const [translateY, setTranslateY] = useState(0);

  useEffect(
    function onIndexChange() {
      if (isStarted && index > 0) {
        const previousLine = lines[index - 1];
        if (!previousLine) {
          return;
        }
        const phraseHeight = document.getElementById(getPhraseLineDomId(previousLine.id))?.scrollHeight;
        if (phraseHeight) {
          // scrollHeight does not take in consideration the margin of the component.
          // Therefore, 8 seems to be the magic number that translates all the element properly.
          setTranslateY((currentOffset) => currentOffset - phraseHeight - 16);
        }
      } else {
        // Reset offset
        setTranslateY(0);
      }
    },
    [index, isStarted, lines],
  );

  return (
    <div
      className="space-y-4 transition-[transform] duration-[350ms]"
      style={translateY ? { transform: `translateY(${translateY}px)` } : undefined}
    >
      {children}
    </div>
  );
}
function hasNotationsInPhrase(phrase: Phrase) {
  return !!phrase.literal_string || (!!phrase.show_notations && phrase.strings.some((s) => s.notations.length > 0));
}
